import Siema from 'siema';

import './styles/generic.scss';

export function init() {
    document.addEventListener("DOMContentLoaded", () => {
        processGallery();
        processEmailLinks();
        // processForms();
    });
}

const processGallery = function() {
    if (document.getElementsByClassName('gallery').length > 0) {
        const siema = new Siema({
            selector: '.gallery',
            loop: true,
            onInit: function() {
                const siema = this as Siema;
                addPrevNextLinks(siema);
                document.addEventListener('keyup', (e) => {
                    if (isElementXPercentInViewport(siema.selector, 10)) {
                        if (e.keyCode == 37) {
                            siema.prev();
                        } else if (e.keyCode == 39) {
                            siema.next()
                        }
                    }
                });
            }
        });
        window.addEventListener('resize', () => {
            addPrevNextLinks(siema);
        });
    }
}

const processEmailLinks = function() {
    const emailLinks = document.querySelectorAll('a.emailLink');
    if (emailLinks.length > 0) {
        emailLinks.forEach((emailLink) => {
            emailLink.addEventListener('click', function() {
                console.log('mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld);
                window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld;
            })
        })
    }

}
const addPrevNextLinks = function(siema: Siema) {
    if (siema.selector.querySelector('.prev') == null) {
        const prevLink = document.createElement('a');
        prevLink.classList.add('prev');
        prevLink.addEventListener('click', () => {
            siema.prev();
        });
        siema.selector.append(prevLink);
    }
    if (siema.selector.querySelector('.next') == null) {
        const nextLink = document.createElement('a');
        nextLink.classList.add('next');
        nextLink.addEventListener('click', () => {
            siema.next();
        });
        siema.selector.append(nextLink);
    }
}

const isElementXPercentInViewport = function(el: HTMLElement, percentVisible: Number) {
    let rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  
    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
};

// const processForms = function() {
//     const forms = document.querySelectorAll('form');
//     if (forms.length > 0) {
//         forms.forEach((form) => {
//             if (form.querySelectorAll('hr').length > 0) {
//                 const prevLink = document.createElement('a');
//                 const nextLink = document.createElement('a');
//                 console.log('hello');
//             }
//         })
//     }
// }